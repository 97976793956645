import { create } from '@storybook/theming';

export default create({
  base: 'light',

  // Colors
  colorPrimary: '#264ABC',
  colorSecondary: '#264ABC',

  // Fonts
  fontBase: '"Hero New", sans-serif',

  // Brand
  brandTitle: 'Payhippo React Components Storybook',
  brandUrl: 'https://payhippo.ng',
  brandImage: 'https://firebasestorage.googleapis.com/v0/b/payhippo.appspot.com/o/client-app-assets%2Fblue-color-logo.png?alt=media&token=f10d7252-80e6-4fa7-a54a-389f97736edb',
  brandTarget: '_self',
});